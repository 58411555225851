import axios, { AxiosResponse, Method } from 'axios';

const backendUrl = '/services/scribe-rejects-dashboard/api'

type Response = Promise<AxiosResponse<any>>;


export const api = {
  generateNewReport(dateRange: string): Response {
    return axios.get(backendUrl + '/report/' + dateRange);
  },
  generateNewReportFromDB(dateRange: string): Response {
    return axios.post(backendUrl + '/report/' + dateRange);
  },

  // if method == 'POST' report going to be generated from the cached DB data (save report)
  // if method == 'GET' report going to be generated from new IA data (update, refresh report)
  storeReportToIAItem(dateRange: string, method: Method): Response {
    return axios({
      method: method,
      url: backendUrl + '/report/save/' + dateRange,
    })
  },
  getReportFromIAItem(dateRange: string): Response {
    return axios.get(backendUrl + '/report/cached/' + dateRange);
  },


  getLogs(): Response {
    const config = {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'responseType': 'blob',
      }
    }
    const resp = axios.get(backendUrl + '/get_logs', config);
    return resp;

  },

}
