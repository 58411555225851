import { IStoreState } from "@/interfaces";

const defaultState: IStoreState = {
    showLoading: false,
    showAlert: false,
    alertMessage: '',
    alertTimeOut: 10000,
  
    reportMode: 'newReport',
    itemsPerPage: 10,
    isSaved: false,
    currentReportDateRange: {
      dateFrom: '',
      dateTo: '',
    },  // e.g. 2021-09-03_2021-09-14
  
    Report: {
      rejected_items: [],
      metadata: {
        last_updated_iso_time: '',
        total_attempted: 0
      }
    },
    statsTableItems: {
      totalAttempted: 0,
      rejectedCount: 0
    },
    filtersData: {
      reasonVariants: {},
      oldPalletVariants: {},
      boxedFilterVariants: {}
    },
    filterState: {
      searchField: '',
      boxedFilter: '',
      reasonFilter: '',
      oldPalletFilter: '',
    }
  };
  
export default defaultState;
