
import { Component, Vue } from 'vue-property-decorator';

import CDateRange from '@/components/CDateRange.vue';
import CLoadingOverlay from '@/components/CLoadingOverlay.vue';
import { commitReportMode } from '@/store/mutations';
import { readCurrentReportDateRange, readReportMode } from '@/store/getters';

@Component({
  components: {
    CDateRange,
    CLoadingOverlay,
  },
})
export default class MainView extends Vue {
  public get reportMode(): string {
    return readReportMode(this.$store);
  }
  
  public set reportMode(mode: string) {
    commitReportMode(this.$store, mode);
  }

  initReport(reportMode: string): void {
    this.reportMode = reportMode;
    
    const dateRange = readCurrentReportDateRange(this.$store);
    const strRange = dateRange.dateFrom + '_' + dateRange.dateTo;
    this.$router.push({
      name: 'NewReportView',
      params: {
        'dateRange': strRange,
      }
    })
  }
}
