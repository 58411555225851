import Vue from 'vue';
import Vuex from 'vuex';

import getters from '@/store/getters';
import actions from '@/store/actions';
import mutations from '@/store/mutations';

import { IStoreState } from '@/interfaces';
import defaultState from '@/store/defaultState';

Vue.use(Vuex)

const state: IStoreState = JSON.parse(JSON.stringify(defaultState));

const DashboardStore =  new Vuex.Store<IStoreState>({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {}
})

export default DashboardStore;
