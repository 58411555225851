
import { Component, Vue, Watch } from 'vue-property-decorator';

import { commitCurrentReportDateRange } from '@/store/mutations';

import moment from 'moment';
import { IChosenDateRange } from '@/interfaces';
import { readCurrentReportDateRange } from '@/store/getters';

@Component
export default class CDateRange extends Vue {
  public defaultRanges: { [key: string]: Array<moment.Moment> } = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment()],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  public customDateRange = [];
  public momentsCustomRange: Array<moment.Moment> = [];
  public showCustomDatePicker = false;

  public defaultRange: Array<moment.Moment> =
    this.defaultRanges['Last 30 Days'];

  public get dateRange(): IChosenDateRange {
    return readCurrentReportDateRange(this.$store);
  }
  public set dateRange(range: IChosenDateRange) {
    commitCurrentReportDateRange(this.$store, range);
  }

  @Watch('momentsCustomRange')
  parseCustomRange() {
    this.dateRange = this.momentArrayToIChosenDate(this.momentsCustomRange);
  }


  mounted() {
    const formated = this.momentArrayToIChosenDate(this.defaultRange);
    commitCurrentReportDateRange(this.$store, formated);
  }

  formatRangeInField(): string {
    const dateRange = this.dateRange;
    const formated = Object.values(dateRange).map((x) =>
      moment(x).format('MMMM DD, YYYY'),
    );
    return formated[0] + ' - ' + formated[1];
  }

  momentArrayToIChosenDate(range: Array<moment.Moment>): IChosenDateRange {
    return {
      dateFrom: range[0].format('YYYY-MM-DD'),
      dateTo: range[1].format('YYYY-MM-DD'),
    };
  }

  setDate(name: string) {
    const chosenRange = this.defaultRanges[name];
    this.dateRange = this.momentArrayToIChosenDate(chosenRange);
  }

  formatCustomRangeToMomentArray() {
    const from = this.customDateRange[0];
    const to = this.customDateRange[1];
    this.momentsCustomRange = [moment(from), moment(to)];
    this.showCustomDatePicker = false;
  }
}
