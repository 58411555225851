import { IStoreState } from "@/interfaces";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
  getShowLoading: (state: IStoreState) => (state.showLoading),
  getShowAllert: (state: IStoreState) => (state.showAlert),
  getAlertMessage: (state: IStoreState) => (state.alertMessage),
  getAlertTimeout: (state: IStoreState) => (state.alertTimeOut),

  getItemsPerPage: (state: IStoreState) => (state.itemsPerPage),
  getIsSaved: (state: IStoreState) => (state.isSaved),
  getCurrentReportDateRange: (state: IStoreState) => (state.currentReportDateRange),
  getLastUpdatedTime: (state: IStoreState) => (state.Report.metadata.last_updated_iso_time),

  getReport: (state: IStoreState) => (state.Report),
  getReportMode: (state: IStoreState) => (state.reportMode),
  getRejectedItems: (state: IStoreState) => (state.Report.rejected_items),
  getAllStatsTableItems: (state: IStoreState) => (state.statsTableItems),

  getFiltersData: (state: IStoreState) => (fieldName: string) => (state.filtersData[fieldName]),
  getAllFiltersData: (state: IStoreState) => (state.filtersData),

  getFilterState: (state: IStoreState) => (filterName: string) => (state.filterState[filterName]),
  getAllFiltersState: (state: IStoreState) => (state.filterState)
};

const { read } = getStoreAccessors<IStoreState, IStoreState>('');

export const readIsLoading = read(getters.getShowLoading);
export const readIsAlert = read(getters.getShowAllert);
export const readAlertMessage = read(getters.getAlertMessage);
export const readAlertTimeout = read(getters.getAlertTimeout);

export const readItemsPerPage = read(getters.getItemsPerPage);
export const readIsSaved = read(getters.getIsSaved);
export const readCurrentReportDateRange = read(getters.getCurrentReportDateRange);
export const readLastUpdatedTime = read(getters.getLastUpdatedTime);

export const readReport = read(getters.getReport);
export const readReportMode = read(getters.getReportMode);
export const readRejectedItems = read(getters.getRejectedItems);
export const readAllStatsTableItems = read(getters.getAllStatsTableItems);

export const readFilterVariantsData = read(getters.getFiltersData);
export const readAllFilterVariants = read(getters.getAllFiltersData);

export const readFilterFieldState = read(getters.getFilterState);
export const readAllFiltersState = read(getters.getAllFiltersState);

export default getters;
