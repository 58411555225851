
import { Component, Vue } from 'vue-property-decorator';

import CAlert from '@/components/CAlert.vue';

import { api } from '@/api';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: { CAlert }
})
export default class App extends Vue{
  public getLogs(): void {
    api.getLogs().then(response => {
      let encodedZipStr = response.data;
      const link = document.createElement('a');
      const zipName = `logs_${uuidv4()}.zip`;
      link.setAttribute('download', zipName);
      link.setAttribute('href', 'data:text/plain;base64, '+ encodedZipStr);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  public redirectToDatePicker(): void {
    if (this.$router.currentRoute.path != '/') {
      this.$router.push('/')
    }
  }
}
