import { IChosenDateRange, IFilterVariants, INewReport, IStatsTableData, IStoreState } from "@/interfaces";
import { getStoreAccessors } from "typesafe-vuex";
import defaultState from "@/store/defaultState";

const mutations = {
  setReportMode(state: IStoreState, payload: string) {
    state.reportMode = payload;
  },
  setItemsPerPage(state: IStoreState, payload: number) {
    state.itemsPerPage = payload;
  },
  setIsSaved(state: IStoreState, payload: boolean) {
    state.isSaved = payload;
  },
  setCurrentReportDateRange(state: IStoreState, payload: IChosenDateRange) {
    state.currentReportDateRange = payload;
  },

  setReport(state: IStoreState, payload: INewReport) {
    state.Report = payload;
  },
  setStatsTableItems(state: IStoreState, payload: IStatsTableData) {
    state.statsTableItems = payload;
  },

  setFilterVariants(state: IStoreState, payload: IFilterVariants) {
    state.filtersData = payload;
  },
  updateFilterState(state: IStoreState, payload: { filterName: string, value: string }) {
    state.filterState[payload.filterName] = payload.value;
  },

  toogleLoading(state: IStoreState, toogler: boolean) {
    state.showLoading = toogler;
  },
  showAlert(state: IStoreState, toogler: boolean) {
    state.showAlert = toogler;
  },
  setAlertMessage(state: IStoreState, message: string) {
    state.alertMessage = message;
  },

  clearState(state: IStoreState) {
    Object.assign(state, defaultState);
  }
};

const { commit } = getStoreAccessors<IStoreState, IStoreState>('');

export const commitReportMode = commit(mutations.setReportMode);
export const commitItemsPerPage = commit(mutations.setItemsPerPage);
export const commitIsSaved = commit(mutations.setIsSaved);
export const commitCurrentReportDateRange = commit(mutations.setCurrentReportDateRange);

export const commitReport = commit(mutations.setReport);
export const commitStatsTableItems = commit(mutations.setStatsTableItems);

export const commitFilterVariants = commit(mutations.setFilterVariants);
export const commitUpdateFilter = commit(mutations.updateFilterState);

export const commitToogleLoading = commit(mutations.toogleLoading);
export const commitToogleAlert = commit(mutations.showAlert);
export const commitAlertMessage = commit(mutations.setAlertMessage);

export const commitClearState = commit(mutations.clearState);

export default mutations;
