import { api } from "@/api";
import { IChosenDateRange, INewReport, IRejectedItem, IStoreState } from "@/interfaces";
import { AxiosError } from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { readAlertTimeout, readLastUpdatedTime } from "./getters";
import { commitAlertMessage, commitFilterVariants, commitReport, commitStatsTableItems, commitToogleAlert, commitToogleLoading } from "./mutations";

type AContext = ActionContext<IStoreState, IStoreState>;

const actions = {
  fetchReport: (context: AContext) => {
    let requestFunc = api.generateNewReport;
    const currentMode = context.state.reportMode;
    requestFunc = currentMode == 'loadReportFromDB' ? api.generateNewReportFromDB
      : currentMode == 'getReportFromIA' ? api.getReportFromIAItem
        : requestFunc;

    commitToogleLoading(context, true);
    const dateRange: IChosenDateRange = context.state.currentReportDateRange;
    const response = requestFunc(dateRange.dateFrom + '_' + dateRange.dateTo);
    response
      .then((resp) => {
        const data: INewReport = resp.data;
        commitReport(context, data);
        commitToogleLoading(context, false);
        commitStatsTableItems(context, {
          'totalAttempted': data.metadata.total_attempted,
          'rejectedCount': data.rejected_items.length,
        });

        const countedReasons: { [key: string]: number } = {};
        const boxedCount: { [key: string]: number } = {};
        const uniqueOldPallets: { [key: string]: number } = {};

        const rejectedItems: Array<IRejectedItem> = context.getters.getRejectedItems;
        rejectedItems.forEach((item: IRejectedItem) => {
          countedReasons[item.reason] > 0
            ? (countedReasons[item.reason] += 1)
            : (countedReasons[item.reason] = 1);

          uniqueOldPallets[item.old_pallet] > 0
            ? (uniqueOldPallets[item.old_pallet] += 1)
            : (uniqueOldPallets[item.old_pallet] = 1);

          const name = item.boxed ? 'Boxed' : 'Standart';
          boxedCount[name] > 0
            ? (boxedCount[name] += 1)
            : (boxedCount[name] = 1);
        });
        commitFilterVariants(context, {
          reasonVariants: countedReasons,
          oldPalletVariants: uniqueOldPallets,
          boxedFilterVariants: boxedCount,
        })
      })
      .catch((error: AxiosError) => {
        if ([404, 500, 400].includes(error.response!.status)) {
          const strRange = `${dateRange.dateFrom} - ${dateRange.dateTo}`;
          const msg = `There is no stored Report for ${strRange} range. \
            You need to generate and save report first.`;
          commitAlertMessage(context, msg)
        }
        commitToogleLoading(context, false);
        commitToogleAlert(context, true);
        setTimeout(() => {
          commitToogleAlert(context, false);
        }, readAlertTimeout(context))
      });

  }
};

const { dispatch } = getStoreAccessors<IStoreState, IStoreState>('');

export const dispatchFetchReport = dispatch(actions.fetchReport);

export default actions;
