import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","fixed":"","color":"grey lighten-5","flat":""}},[_c(VToolbarTitle,{staticClass:"mr-2 text-h6",staticStyle:{"cursor":"pointer"},on:{"click":_vm.redirectToDatePicker}},[_vm._v(" Scribe Rejections Dashboard ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","depressed":""},on:{"click":_vm.getLogs}},[_vm._v("Download logs")])],1),_c('c-alert'),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }